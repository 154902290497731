import React from 'react';

const ClapIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" {...props}>
    <g {...props}>
      <path d="M5273.1 2400.1v-2c0-2.8-5-4-9.7-4s-9.7 1.3-9.7 4v2a7 7 0 002 4.9l5 4.9c.3.3.4.6.4 1v6.4c0 .4.2.7.6.8l2.9.9c.5.1 1-.2 1-.8v-7.2c0-.4.2-.7.4-1l5.1-5a7 7 0 002-4.9zm-9.7-.1c-4.8 0-7.4-1.3-7.5-1.8.1-.5 2.7-1.8 7.5-1.8s7.3 1.3 7.5 1.8c-.2.5-2.7 1.8-7.5 1.8z" />
      <path d="M5268.4 2410.3c-.6 0-1 .4-1 1s.4 1 1 1h4.3c.6 0 1-.4 1-1s-.4-1-1-1h-4.3zM5272.7 2413.7h-4.3c-.6 0-1 .4-1 1s.4 1 1 1h4.3c.6 0 1-.4 1-1s-.4-1-1-1zM5272.7 2417h-4.3c-.6 0-1 .4-1 1s.4 1 1 1h4.3c.6 0 1-.4 1-1 0-.5-.4-1-1-1z" />
      <g>
        <path d="M57.1 14.7c1.3 0 2.3-1 2.3-2.3V4.8c0-1.3-1-2.3-2.3-2.3s-2.3 1-2.3 2.3v7.6c0 1.2 1.1 2.3 2.3 2.3zM43 16.8c.4.7 1.2 1.2 2 1.2l1.2-.3c1.1-.6 1.5-2.1.8-3.2L43.2 8a2.3 2.3 0 00-3.2-.8 2.3 2.3 0 00-.8 3.2l3.8 6.4zM68.1 17.6l1.2.3c.8 0 1.6-.4 2-1.2l3.8-6.5c.6-1.1.3-2.5-.8-3.2a2.4 2.4 0 00-3.2.8l-3.8 6.5c-.7 1.3-.3 2.7.8 3.3zM94.4 50.5c-.5-.5-1.2-1-1.8-1.3l.1-.1a6.6 6.6 0 00-2.4-10.8c1-2.4.5-5.3-1.5-7.2-2-2-4.8-2.5-7.2-1.5a6.5 6.5 0 00-10.3-2.7 6.5 6.5 0 00-8.5-3.3c-.4-.8-.8-1.7-1.5-2.3a6.6 6.6 0 00-9.3-.1l-9 9a6.5 6.5 0 00-10.5-1.9L20.7 40a24.5 24.5 0 00-7.1 16.5l-8.1 8.1a6.4 6.4 0 000 9.2l15.7 15.7a6.3 6.3 0 009.1 0l1.9-1.9 7.9 7.9a6.6 6.6 0 009.2 0l8.2-8.2c6-.2 11.9-2.5 16.5-7.1l20.7-20.7c2.4-2.3 2.3-6.4-.3-9zM63.8 28.1c.8-.8 2.1-.7 3 .1.8.8.9 2.2.1 3l-5 5a7 7 0 00-1.2-1.8c-.6-.6-1.2-.9-1.9-1.3l5-5zm-40 15.1l11.8-11.8c.8-.8 2.1-.7 3 .2.8.8 1 2.1.2 2.9l-11 11c-.8.8-.8 2.3 0 3.1.9.9 2.3.9 3.1 0l24.2-24.2c.8-.8 2.1-.8 3 .1.8.8.9 2.2.1 3L39.6 46.1a24.5 24.5 0 00-7.1 16.5l-5 5-9.4-9.6a19.8 19.8 0 015.7-14.8zm3.5 43.2a2 2 0 01-2.9 0L8.6 70.7a2 2 0 010-2.9l6.5-6.5 9.4 9.4-.1.1a6.6 6.6 0 000 9.2l4.7 4.7-1.8 1.7zm18.9 6.1a2 2 0 01-2.9 0L27.5 76.7a2 2 0 010-2.9l6.5-6.5 18.6 18.6-6.4 6.6zm45.3-35.9L71.7 76.3l-.8.9c-4.1 4.1-9.5 6-14.8 5.8L37 64a19.8 19.8 0 015.8-14.8l11.8-11.8c.8-.8 2.1-.7 3 .1.8.8.9 2.2.2 2.9l-11.1 11c-.9.9-.9 2.3 0 3.1.9.9 2.3.9 3.1 0L74 30.4c.8-.8 2.1-.8 3 .1.9.9.9 2.2.1 3l-16 16c-.9.9-.9 2.3 0 3.1.9.9 2.3.9 3.1 0l18.5-18.5c.8-.8 2.1-.8 3 .1.9.9.9 2.2.1 3L67.3 55.8c-.9.9-.9 2.3 0 3.1.9.9 2.3.9 3.1 0l16-16a2 2 0 013 .1c.9.9.9 2.2.1 3l-16 16c-.7.7-.8 1.6-.5 2.4l.5.7c.9.9 2.3.9 3.1 0l11.7-11.7c.7-.7 2.1-.6 2.9.2.9.9 1 2.2.3 3z" />
      </g>
    </g>
  </svg>
);

export default ClapIcon;
